import "./styles/index.scss";
import "bootstrap";

//webpack.ProvidePlugin is supposed to do this but doesn't
import Jquery from 'jquery';

declare global {
    interface Window { $: JQuery;jQuery:JQuery }
}

export default Jquery;


$(document).ready(() => {
    var $submit = $('#pay-button');
    var $checkbox = $('#pay');

    $submit.prop('disabled', true);

    $checkbox.on('click', function () {
        if ($checkbox.is(':checked')) {
            $submit.removeClass('disabled');
        } else {
            $submit.addClass('disabled');
        }
    });
});
